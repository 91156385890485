<template>
    <div v-if="page === 'connect'" class="content-container checkout-box radius-anchor max-w-xl mx-auto ccss--user-profile">
        <div class="text-center">
            <h2 class="text-3xl font-extrabold">
                {{ $t('wallettokenconnect.connect.title') }}
            </h2>
            <p>{{ $t('wallettokenconnect.connect.description') }}</p>
        </div>
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <div class="pt-8">
                <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
                    <!--                    <div class="mb-3">-->
                    <!--                        <label for="email" class="block text-xs" :class="{'text-red-500': errors.company_code}"-->
                    <!--                        >{{ $t('fields.company_code') }} *</label>-->
                    <!--                        <div class="mt-1">-->
                    <!--                            <Field type="number" v-model="form.company_code" name="company_code"-->
                    <!--                                   min=""-->
                    <!--                                   max="100"-->
                    <!--                                   class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent"-->
                    <!--                                   :class="{'error': errors.company_code}"-->
                    <!--                            />-->
                    <!--                        </div>-->
                    <!--                    </div>-->

                    <div class="mb-3">
                        <label
                            for="email"
                            class="block text-xs"
                            :class="{
                                'text-red-500':
                                    errors.company_code_1 ||
                                    errors.company_code_2 ||
                                    errors.company_code_3 ||
                                    errors.company_code_4 ||
                                    errors.company_code_5 ||
                                    errors.company_code_6 ||
                                    errors.company_code_7 ||
                                    errors.company_code_8 ||
                                    errors.company_code_9,
                            }"
                            >{{ $t('fields.company_code') }} *</label
                        >

                        <div class="mt-1 company-code-fields">
                            <Field
                                type="number"
                                v-model="company_code_1"
                                name="company_code_1"
                                @input="constructCompanyCode"
                                class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent text-center radius-anchor"
                            />
                            <Field
                                type="number"
                                v-model="company_code_2"
                                name="company_code_2"
                                @input="constructCompanyCode"
                                class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent text-center radius-anchor"
                            />
                            <Field
                                type="number"
                                v-model="company_code_3"
                                name="company_code_3"
                                @input="constructCompanyCode"
                                class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent text-center radius-anchor"
                            />
                            <Field
                                type="number"
                                v-model="company_code_4"
                                name="company_code_4"
                                @input="constructCompanyCode"
                                class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent text-center radius-anchor"
                            />
                            <Field
                                type="number"
                                v-model="company_code_5"
                                name="company_code_5"
                                @input="constructCompanyCode"
                                class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent text-center radius-anchor"
                            />
                            <Field
                                type="number"
                                v-model="company_code_6"
                                name="company_code_6"
                                @input="constructCompanyCode"
                                class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent text-center radius-anchor"
                            />
                            <Field
                                type="number"
                                v-model="company_code_7"
                                name="company_code_7"
                                @input="constructCompanyCode"
                                class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent text-center radius-anchor"
                            />
                            <Field
                                type="number"
                                v-model="company_code_8"
                                name="company_code_8"
                                @input="constructCompanyCode"
                                class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent text-center radius-anchor"
                                :class="{ error: errors.company_code }"
                            />
                            <Field
                                type="number"
                                v-model="company_code_9"
                                name="company_code_9"
                                @input="constructCompanyCode"
                                class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent text-center radius-anchor"
                            />
                        </div>
                    </div>

                    <div class="mb-3 grid gap-4 grid-cols-3">
                        <div class="col-span-2">
                            <label class="block text-xs" :class="{ 'text-red-500': errors.cardnumber }">{{ $t('fields.cardnumber') }} *</label>
                            <div class="mt-1">
                                <Field
                                    type="text"
                                    v-model="form.cardnumber"
                                    name="cardnumber"
                                    class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent radius-anchor"
                                    :class="{ error: errors.cardnumber }"
                                />
                            </div>
                        </div>

                        <div class="col-span-1">
                            <label class="block text-xs" :class="{ 'text-red-500': errors.pincode }">{{ $t('fields.pincode') }} *</label>
                            <div class="mt-1">
                                <Field
                                    type="text"
                                    v-model="form.pincode"
                                    name="pincode"
                                    class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent radius-anchor"
                                    :class="{ error: errors.pincode }"
                                />
                            </div>
                        </div>
                    </div>

                    <div v-if="nameRequired == true" class="grid grid-cols-2 w-full gap-4">
                        <div class="mb-3">
                            <label for="email" class="block text-xs" :class="{ 'text-red-500': errors.firstname }"
                                >{{ $t('fields.firstname') }} *</label
                            >
                            <div class="mt-1">
                                <Field
                                    type="text"
                                    v-model="form.firstname"
                                    name="firstname"
                                    class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent radius-anchor"
                                    :class="{ error: errors.firstname }"
                                />
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="email" class="block text-xs" :class="{ 'text-red-500': errors.lastname }"
                                >{{ $t('fields.lastname') }} *</label
                            >
                            <div class="mt-1">
                                <Field
                                    type="text"
                                    v-model="form.lastname"
                                    name="lastname"
                                    class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent radius-anchor"
                                    :class="{ error: errors.lastname }"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="sm:flex text-default">
                        <div class="w-full mt-5 relative">
                            <button
                                type="submit"
                                class="w-full inline-flex justify-center items-center mt-3 px-6 py-3 btn btn-primary text-base leading-6 font-medium rounded-sm focus:outline-none transition ease-in-out duration-150"
                            >
                                {{ $t('wallettokenconnect.connect.button') }}
                                <svg
                                    v-if="saving"
                                    class="animate-spin ml-3 h-5 w-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                    <path
                                        class="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                            </button>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    </div>

    <div v-else-if="page === 'success'" class="content-container checkout-box radius-anchor max-w-xl mx-auto ccss--user-profile">
        <h2 class="text-center text-3xl font-extrabold">
            {{ $t('wallettokenconnect.success.title') }}
        </h2>
        {{ $t('wallettokenconnect.success.description') }}
    </div>
</template>

<script>
import { Field, Form } from 'vee-validate';
import * as _api from '../lib/api';
import * as _config from '../lib/config';
import * as _notification from '../lib/notification';
import * as yup from 'yup';

export default {
    name: 'UserProfile',
    components: {
        Field,
        Form,
    },

    beforeMount() {
        const config = _config.getSettings().wallettoken_company;

        if (config.active != true) {
            location.href = '/403';
            return;
        }
    },

    mounted() {

        if (this.$route.query.companycode) {
            for (let i = 1; i <= 9; i++) {
                this['company_code_' + i] = this.$route.query.companycode.charAt(i - 1);
            }
        }

        this.companyCodeFieldEaser();
    },
    data() {
        let validations;

        if (_config.getSettings().wallettoken_company.name_required == true) {
            validations = {
                firstname: yup.string().required().max(255),
                lastname: yup.string().required().max(255),
                cardnumber: yup.string().required().max(255),
                pincode: yup.string().required().max(255),
                company_code_1: yup.string().required().max(1),
                company_code_2: yup.string().required().max(1),
                company_code_3: yup.string().required().max(1),
                company_code_4: yup.string().required().max(1),
                company_code_5: yup.string().required().max(1),
                company_code_6: yup.string().required().max(1),
                company_code_7: yup.string().required().max(1),
                company_code_8: yup.string().required().max(1),
                company_code_9: yup.string().required().max(1),
            };
        } else {
            validations = {
                cardnumber: yup.string().required().max(255),
                pincode: yup.string().required().max(255),
                company_code_1: yup.string().required().max(1),
                company_code_2: yup.string().required().max(1),
                company_code_3: yup.string().required().max(1),
                company_code_4: yup.string().required().max(1),
                company_code_5: yup.string().required().max(1),
                company_code_6: yup.string().required().max(1),
                company_code_7: yup.string().required().max(1),
                company_code_8: yup.string().required().max(1),
                company_code_9: yup.string().required().max(1),
            };
        }
        const schema = yup.object().shape(validations);

        return {
            schema,
            saving: false,
            page: 'connect',
            form: {
                firstname: this.$route.query.firstname || '',
                lastname: this.$route.query.lastname || '',
                cardnumber: this.$route.query.cardnumber || '',
                company_code: this.$route.query.company_code || '',
                pincode: this.$route.query.pincode || '',
            },
            company_code_1: '',
            company_code_2: '',
            company_code_3: '',
            company_code_4: '',
            company_code_5: '',
            company_code_6: '',
            company_code_7: '',
            company_code_8: '',
            company_code_9: '',
        };
    },

    methods: {
        async onSubmit(data) {
            if (this.saving === true) {
                return;
            }

            //add company code to data and remove 9 parts
            for (let i = 1; i <= 9; i++) {
                let code = 'company_code_' + i;
                delete data[code];
            }
            data.company_code = this.company_code;

            this.saving = true;

            const response = await _api.post('wallettokens/connect', data, {});

            if (response && response.result === 'success') {
                this.page = 'success';
            } else if (response && response.result === 'failed') {
                _notification.set(this.$t(response.message), 'error');
            } else {
                console.log('unkn');
            }
            this.saving = false;
        },

        constructCompanyCode(evt) {
            let value = evt.target.value;
            let field = evt.target.name;
            this[field] = value;

            //autojump to next field
            let fieldNumber = field.replace('company_code_', '');
            let nextField = document.getElementsByName('company_code_' + (parseInt(fieldNumber) + 1))[0];
            if (nextField) {
                nextField.focus();
                nextField.value = '';
            } else {
                document.getElementsByName('company_code_' + fieldNumber)[0].blur();
            }
        },

        companyCodeFieldEaser() {
            let companyCodeFields = document.querySelectorAll('[name^="company_code_"]');

            companyCodeFields.forEach(function (elem) {
                elem.addEventListener('keydown', function (event) {
                    if (event.keyCode == 8) {
                        let fieldNumber = elem.name.replace('company_code_', '');
                        elem.value = '';

                        if (fieldNumber > 1) {
                            let previousField = document.getElementsByName('company_code_' + (parseInt(fieldNumber) - 1))[0];

                            if (previousField) {
                                previousField.value = '';
                                previousField.focus();
                            }
                        }
                    }
                });
            });
        },
    },
    computed: {
        nameRequired() {
            return _config.getSettings().wallettoken_company.name_required || false;
        },

        company_code() {
            let comp_code = '';
            for (let i = 1; i <= 9; i++) {
                comp_code += String(this['company_code_' + i]);
            }

            return comp_code;
        },
    },
};
</script>
